import React from "react";
import "../App.css";
function Malipo() {
  return (
    <div>
      <main>
        <h1>Malipo ya Subscription</h1>

        <h4 className="payment-title"> Jinsi ya kulipia</h4>
        <strong>Njia ya Direct</strong>
        <div className="direct-pay">
          <p>
            Kuna njia mbili za kulipia ili kuweza kuunganishwa na huduma hii
          </p>
          <p>
            Njia ya kwanza ni njia ya Direct(moja kwa moja) ambapo utatuma kiasi
            kilichotajwa hapo juu kupitia <br />
            <span className="mpesa">M-PESA</span>,{" "}
            <span className="halopesa">Halopesa</span> au{" "}
            <span className="airtel-money">
              Airtel <span className="money">money</span>
            </span>
            . <br />
            Namba za kulipia hizi hapa chini
          </p>

          <p className="paragraph-mpesa">
            <img
              src="https://imgur.com/fwIgi4I.png"
              alt="mpesa"
              width="100px"
            />
            <span className="bigger-number"> 0767 456 850 </span>
            <br /> JINA YUSUPH DAUDI MURO
          </p>
          <p className="paragraph-halopesa">
            <img
              src="https://imgur.com/qZ8QF92.png"
              alt="halopesa"
              width="100px"
            />
            <span className="bigger-number"> 0621 842 779 </span> <br /> JINA
            YUSUPH DAUDI MURO
          </p>
          <p className="paragraph-airtel">
            <img
              src="https://imgur.com/z0E6acC.png"
              alt="airtel money"
              width="100px"
            />
            <span className="bigger-number">0783 377 152</span>
            <br /> JINA YUSUPH DAUDI MURO
          </p>
          <p>
            Baada ya kufanikisha malipo utatuma majina yako pamoja na email
            uliosajilia Storiy ili kuweza kuunganishwa na huduma hii
          </p>
        </div>

        <strong>Njia ya AzamPay</strong>
        <p className="maelezo-azam">
          Unaweza pia kulipia kwa njia ya pili ya AzamPay ambapo utabonyeza
          button iliyoandikwa
          <span className="payment-azam">Proceed with payment</span> hapo chini{" "}
          <br />
          kisha utalipia kwa Azampesa au TigoPesa na nyingine ambazo
          zimeorodheshwa.. <br />
          <p>
            {" "}
            Baada ya kufanikisha malipo utatuma majina yako pamoja na email
            uliosajilia Storiy ili kuweza kuunganishwa na huduma hii
          </p>
          <br />
          <small>
            <span className="red">
              *Refresh page yako kama huoni jina la Storiy na kiasi cha
              kulipia..
            </span>
          </small>
        </p>
      </main>

      <div className="malipo">
        <iframe
          src="https://payments.azampay.co.tz/?id=f207c4e5-0447-4ceb-a696-15715ae05089"
          className="iframe"
          title="Storiy subscription"
        ></iframe>
      </div>
    </div>
  );
}

export default Malipo;
