import React from "react";
import "../portfolio.css";
import myProfile from "../images/my-profile.jpg";
import Whatsapp from "../images/whatsapp.png";
import Call from "../images/phone.png";
import Email from "../images/email.png";
import zii from "../images/portfolio/logo-section/zii.jpg";
import balqees from "../images/portfolio/logo-section/balqees.jpg";
//import balqeesTwo from "../images/portfolio/logo-section/balqees2.jpg";
import purple from "../images/portfolio/logo-section/purple.jpg";
import duCare from "../images/portfolio/logo-section/du-care.jpg";
import miStore from "../images/portfolio/logo-section/mi-store.jpg";
import otherLogos from "../images/portfolio/logo-section/other-logos.jpg";

import posterOne from "../images/portfolio/poster-section/poster-designI.jpg";
import otherPosters from "../images/portfolio/poster-section/other-posters.jpg";

import kahawa from "../images/portfolio/3d-section/kahawa-blender.jpg";
import cupcake from "../images/portfolio/3d-section/cupcake-final.jpg";

import landingPage from "../images/portfolio/landing-page.jpg";
import union from "../images/portfolio/union.jpg";
import unionSketch from "../images/portfolio/union-sketch.png";

import storiyBlack from "../images/portfolio/storiy-section/storiy-black.jpg";
import storiyWeb from "../images/portfolio/storiy-section/website-pages.jpg";
import storiyApp from "../images/portfolio/storiy-section/android-app2.jpg";
import storiyPlus from "../images/portfolio/storiy-section/storiy-plus.jpg";

import { Link } from "react-router-dom";

function Portfolio() {
  return (
    <div className="portfolio-body">
      <div className="portfolio-header">
        <div className="portfolio-header-content">
          <img src={myProfile} alt="my-profile" />
          <ul className="portfolio-nav">
            <li>
              <strong>HOME</strong>
            </li>
            <li>
              <Link className="nav-onhover" to={"/about"}>
                ABOUT
              </Link>
            </li>
            <li>
              <Link className="nav-onhover" to={"/contact"}>
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="portfolio-main">
        <div className="portfolio-intro">
          <h1>
            <span className="first-heading"> Hello, I'm</span> <br />
            <span className="second-heading"> Yusuf Muro</span>
          </h1>
          <h4>Graphic Designer . Web Developer</h4>
          <a href="https://firebasestorage.googleapis.com/v0/b/storiy-app.appspot.com/o/my-resumeII.pdf?alt=media&token=b88e7533-c335-45ff-925a-a800a6566135">
            <button>my resume (PDF Version)</button>
          </a>

          <div className="portfolio-social-medias">
            <a href="https://wa.me/+255767456850">
              <img
                className="portfolio-link-icon"
                src={Whatsapp}
                alt="whatsapp"
              />
            </a>
            <a href="tel:+255767456850">
              <img className="portfolio-link-icon" src={Call} alt="call" />
            </a>
            <a href="mailto:yusufmuro98@gmail.com">
              <img className="portfolio-link-icon" src={Email} alt="email" />
            </a>
          </div>
        </div>
      </div>

      <div className="portfolio-section">
        <h3>Portfolio</h3>
        <div className="portfolio-jump-to">
          <ul>
            <h4>
              SECTIONS(<span className="jump-to">Jump To</span>)
            </h4>
            <li>
              <a href="#section-1">Logo Design/Brand Identity</a>
            </li>
            <li>
              <a href="#section-2">Poster/Label Design</a>
            </li>
            <li>
              <a href="#section-3">3D Modelling to Mockup</a>
            </li>
            <li>
              <a href="#section-4">Web Design(UI/UX Design)</a>
            </li>
            <li>
              <a href="#section-5">Video Compositing and editing</a>
            </li>
            <li>
              <a href="#section-6">Sketch to Design</a>
            </li>
            <li>
              <a href="#section-7">Storiy: my Personal Project</a>
            </li>
          </ul>
        </div>

        <div className="logo-design">
          <h2 id="section-1">Logo Design/Brand Identity</h2>
          <p>
            Assisting clients in Logo designing and work with them throughout
            the design process. Usually the process involves different
            variations of the Logo in which clients participate in choosing the
            correct one that resonates with their audiences.
          </p>
          <small>
            <b>Software used:</b> Adobe Illustrator
          </small>
          <hr />
          <div className="logo-design-images">
            <img src={zii} alt="zii" />

            <img src={balqees} alt="balqees" />

            <img src={purple} alt="purple" />
            <img src={duCare} alt="ducare" />
            <img src={miStore} alt="ducare" />
            <img src={otherLogos} alt="ducare" />
          </div>
        </div>

        <div className="poster-design">
          <h2 id="section-2">Poster Design/Label Design</h2>
          <p>
            With the use of different Design Principles such as Hierarchy,
            Balance and others, Posters and Label design process aim at
            navigating viewers creatively across the design so they get the
            exact message intended.
          </p>
          <small>
            <b>Software used:</b> Adobe Photoshop
          </small>
          <hr />
          <img src={posterOne} alt="posterI" />
          <img src={otherPosters} alt="other-posters" />
        </div>
        <div className="threed-modelling-mockup">
          <h2 id="section-3">3D modelling to mockup</h2>
          <p>
            Creating a relevant 3D model from scratch to designing a mockup in
            Photoshop that will be used and re-used by other Designers.
          </p>
          <small>
            <b>Software used:</b> Blender, Adobe Photoshop
          </small>
          <hr />
          <img src={kahawa} alt="kahawa" />
          <img src={cupcake} alt="cupcake" />
        </div>
        <div className="web-design">
          <h2 id="section-4">Web Design(UI/UX Design)</h2>
          <p>
            Designing a responsive web design and creating a prototype that will
            give a client the actual feel of the website before starting
            development.
          </p>
          <small>
            <b>Software used:</b> Figma
          </small>
          <hr />
          <img src={landingPage} alt="landing-page" />
        </div>
        <div className="video-editing">
          <h2 id="section-5">Video compositing and editing</h2>
          <p>
            created the video in 2021 for my Youtube channel using Adobe After
            Effects for compositing and Adobe Premiere Pro for editing.It is one
            of the 4 part mini documentary about the Ottoman Empire.
          </p>
          <small>
            <b>Software used:</b> Adobe After Effects, Adobe Premiere Pro
          </small>
          <hr />
          <iframe
            className="youtube-video"
            src="https://www.youtube.com/embed/ryorGU9umcc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div className="sketch-design">
          <h2 id="section-6">Sketch to Design</h2>

          <p>
            With drawing skills, sketching is the easy and fast way of
            expressing concepts early on in the design process. Sketch drawn in
            Samsung Note 9
          </p>
          <small>
            <b>Software used:</b> Adobe Illustrator, Sketchbook(Android)
          </small>
          <hr />
          <img src={unionSketch} alt="union-sketch" />
          <img src={union} alt="union" />
        </div>
        <div className="storiy-project">
          <h2 id="section-7">Storiy: my Personal Project</h2>

          <p>
            Storiy is an online reading platform that aims at creating an easy
            way for reading and discovering books that are written by our
            authors in Swahili language. Storiy plus is the English version of
            it(currently in progress).
            <br />
            With this project I was able to design everything from book covers
            to web and an Androip app. Link to the website here:{" "}
            <a href="https://storiy.co.tz/">storiy.co.tz</a>{" "}
          </p>
          <hr />
          <img src={storiyBlack} alt="storiy-section" />
          <img src={storiyWeb} alt="storiy-section" />
          <img src={storiyApp} alt="storiy-section" />
          <img src={storiyPlus} alt="storiy-section" />
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
