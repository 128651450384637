import React from "react";
import appIcon from "../ic_launcher.png";
import "../Android.css";

import stepOne from "../stepone.jpg";
import stepTwo from "../steptwo.jpg";
import stepThree from "../stepthree.jpg";

function Android() {
  const downloadLink =
    "https://firebasestorage.googleapis.com/v0/b/storiy-app.appspot.com/o/Storiy_v1.0.0_beta.apk?alt=media&token=73966a24-a3e5-4a23-add0-19be07c70177";

  return (
    <div className="android">
      <h1>Storiy app Android</h1>
      <div className="download-link">
        <img src={appIcon} alt="Storiy app icon" />

        <div className="app-title">
          <strong className="storiy-title">Storiy app </strong>{" "}
          <span>
            <small>v1.0.0 beta</small>
          </span>
          <br />
          <small>kwa mfumo wa Android</small>
        </div>

        <a href={downloadLink}>
          <div className="download-container">
            <button className="download-button">Download</button>
            <small>11.37 MB</small>
          </div>
        </a>
      </div>

      <hr />
      <div className="how-to">
        <h3>Jinsi ya kudownload</h3>
        <ol className="o-list">
          <li>
            <p>Bonyeza button iliyoandikwa hapo juu Download</p>
          </li>
          <li>
            <p>
              Ikishamaliza kudownload nenda sehemu ulipodownload (Download
              Folder) file lako na litakuwa limeandikwa Storiy.v1.0.0_beta.apk
              kama inavyoonekana kwenye picha hapo chini{" "}
            </p>
            <img src={stepOne} alt="step one" />
          </li>
          <li>
            <p>
              Click kuanza kuinstall. Kama ni mara ya kwanza kuinstall
              application nje ya Play Store, simu yako itakuletea option ya
              kuruhusu hivyo utairuhusu ili kuweza kuinstall Application.
            </p>
            <img src={stepTwo} alt="step two" />
          </li>
          <li>
            <p>Sasa ukiclick tena itainstall na utaweza kuanza kutumia!</p>
            <img src={stepThree} alt="step three" />
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Android;
