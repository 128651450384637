import React from "react";
import "../contact.css";
import { Link } from "react-router-dom";
import myProfile from "../images/my-profile.jpg";
import Whatsapp from "../images/whatsapp.png";
import Call from "../images/phone.png";
import Email from "../images/email.png";

function Contact() {
  return (
    <div className="contact-body">
      <div className="contact-header">
        <div className="contact-header-content">
          <img src={myProfile} alt="my-profile" />
          <ul className="contact-nav">
            <li>
              <Link className="nav-onhover" to={"/portfolio"}>
                HOME
              </Link>
            </li>
            <li>
              <Link className="nav-onhover" to={"/about"}>
                ABOUT
              </Link>
            </li>
            <li>
              <strong>CONTACT</strong>
            </li>
          </ul>
        </div>
      </div>

      <div className="contact-section">
        <h2>CONTACTS;</h2>
        <ul className="contact-list">
          <li>0767 456 850</li>
          <li>0621 842 779</li>
          <li>yusufmuro98@gmail.com</li>
        </ul>

        <div className="portfolio-social-medias">
          <a href="https://wa.me/+255767456850">
            <img
              className="portfolio-link-icon"
              src={Whatsapp}
              alt="whatsapp"
            />
          </a>
          <a href="tel:+255767456850">
            <img className="portfolio-link-icon" src={Call} alt="call" />
          </a>
          <a href="mailto:yusufmuro98@gmail.com">
            <img className="portfolio-link-icon" src={Email} alt="email" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
