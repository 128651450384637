import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Premium from "./pages/Premium";
import Malipo from "./pages/Malipo";
import Error from "./pages/Error";
import Android from "./pages/Android";
import Portfolio from "./pages/Portfolio";
import About from "./pages/About";
import Contact from "./pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/malipo" element={<Malipo />} />
        <Route path="*" element={<Error />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/premium/5"
          element={
            <Premium
              title="Sofia"
              image="https://imgur.com/guJIyKq.jpg"
              price="Tshs 10,000/-"
              lipiaLink="https://payments.azampay.co.tz/?id=b3fbda5c-2f97-4f7b-88c0-95fc1dd040f3"
            />
          }
        />
        <Route
          path="/premium/9"
          element={
            <Premium
              title="Tom Green: Mshike Mshike"
              image="https://i.imgur.com/4COZnrg.jpg"
              price="Tshs 6,000/-"
              lipiaLink="https://payments.azampay.co.tz/?id=3147f5a2-4915-46bb-956f-7df2dff2fdd3"
            />
          }
        />
        <Route path="/android" element={<Android />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
