import React from "react";
import logo from "../storiy-plus-logo.png";
import "../App.css";

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="main-title">
          <h1 className="read">Read</h1>
          <h1>Digitally</h1>
        </div>
        <p className="paragraph">
          Read excellent stories and books from anywhere you are for free and
          premium books at the cheapest subscription ever!
        </p>

        <h6>
          coming soon..<span className="next">.</span>
        </h6>
      </header>
    </div>
  );
}

export default Home;
