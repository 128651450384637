import React from "react";
import "../about.css";
import myProfile from "../images/my-profile.jpg";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className="about-body">
      <div className="about-header">
        <div className="about-header-content">
          <img src={myProfile} alt="my-profile" />
          <ul className="about-nav">
            <li>
              <Link className="nav-onhover" to={"/portfolio"}>
                HOME
              </Link>
            </li>
            <li>
              <strong>ABOUT</strong>
            </li>
            <li>
              <Link className="nav-onhover" to={"/contact"}>
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="portfolio-main">
        <div className="portfolio-intro">
          <h1>
            <span className="first-heading"> Hello, I'm</span> <br />
            <span className="second-heading"> Yusuf Muro</span>
          </h1>
          <div className="about-paragraphs">
            <p>
              A passionate self-taught graphic designer with a 5 year experience
              working as a freelancer assisting clients in visualizing their
              products, ideas and dreams.
            </p>

            <p>
              Worked with different small businesses and individual clients in
              the span of 5 years in Brand designing. This experience enhanced
              my designing skills as well as my communication skills with
              different clients. Also it enhanced my marketing abilities from
              brand awareness through a client purchasing my services to client
              being my advocate.
            </p>

            <p>
              Storiy, my personal project which is an online reading platform.
              Through this project I was able to design everything from UI/UX to
              book covers to a finished web and an Android app. Link to the
              website is www.storiy.co.tz and storiyplus.com which is an english
              version(currently in progress).
            </p>
          </div>

          <div className="portfolio-art"></div>
        </div>
      </div>
    </div>
  );
}

export default About;
